<template>
  <v-row no-gutters class="d-flex justify-center align-center">
    <v-col lg="8" sm="8" md="10" xs="12">
      <v-row no-gutters class="select-account">
        {{ $t('login.select_account') }}
      </v-row>
      <v-select
        class="custom-select pb-6"
        v-model="selectedAccountId"
        item-text="name"
        item-value="id"
        outlined
        dense
        hide-details
        color="#39af49"
        :items="accounts"
      />
      <v-row no-gutters class="access-account">
        <v-btn
          class="text-none platform-access-button"
          width="100%"
          :loading="isLoading"
          @click="selectedAccount"
        >
          <span class="platform-access">
            {{ $t('login.platform_access') }}
          </span>
          <font-awesome-icon class="icon-access" icon="sign-in-alt" />
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AccountSelector',

  props: {
    accounts: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      isLoading: false,
      selectedAccountId: this.accounts[0].id,
    }
  },

  methods: {
    async selectedAccount() {
      try {
        this.isLoading = true
        await this.$api.auth.selectAccount(this.selectedAccountId)
        this.$router.push({
          path: '/my_farm',
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.select-account {
  color: #1a2b46;
  font-family: 'Rubik';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-bottom: 35px;
}
.custom-select {
  color: #0e180c;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  border-radius: 4px;
  height: 30px;
  min-width: 100px;
}
.access-account {
  padding-top: 27px;
}
.platform-access-button {
  background-color: #39af49 !important;
}
.platform-access {
  color: #fff;
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0;
}
.icon-access {
  color: #fff !important;
  font-size: 10px;
  margin-left: 5px;
}
</style>
